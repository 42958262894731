// Navgation Links

export const nav_links = [
  { href: "/", key: "home", label: "Home" },
  { href: "/news", key: "news", label: "News" },
  { href: "/contact", key: "contact ", label: "Contact us" },
  { href: "/about", key: "about ", label: "About Us" },
];

// Services

export const wegen_data = [
  {
    img: "/pool.png",
    href: "https://pool.et/",
  },
  {
    img: "/origin_logo.png",
    href: "https://www.originlandgeneraltradingplc.com/",
  },
  {
    img: "/wota.png",
    href: "",
  },
  {
    img: "/5Star.png",
    href: "",
  },
  {
    img: "/tabor.png",
    href: "",
  },
  {
    img: "/union.jpg",
    href: "",
  },
  {
    img: "/union1.jpg",
    href: "",
  },
];

// Home News

export const news = [
  {
    id: 1,
    img: "/pool.png",
    title: "Transport: Pool Meter Taxi",
    desc: " Pool Meter Taxi, a flagship in the transport sector under our corporate umbrella, introduces an innovative ride-sharing solution. Leveraging advanced technology, the service promises efficient and affordable transportation, enhancing commuter experiences. This marks a significant stride towards sustainable urban mobility and underscores our commitment to redefining the future of transport.  ",
  },
  {
    id: 2,
    img: "/import.jpeg",
    title: "Import-Export: Origin Land ",
    desc: " Origin Land, our distinguished import-export company, takes a leap forward in facilitating international trade. Leveraging strategic partnerships and streamlined logistics, Origin Land ensures seamless import-export operations, fostering global commerce. This milestone reinforces our commitment to creating value across borders, promoting economic growth, and solidifying our position as a key player in the global trade landscape.  ",
  },

  {
    id: 3,
    img: "/union.jpg",
    title: "Education: Union Academy",
    desc: " In the realm of education, our dedicated entity strives for excellence. Through innovative programs and a student-centric approach, Union acadamy empowers minds, shaping the leaders of tomorrow. With a commitment to quality education, the company stands as a beacon for knowledge, fostering a brighter future through learning and enlightenment.  ",
  },
];

// News Page

export const news_page = [
  {
    id: 1,
    img: "/pool.png",
    title: "Transport: Pool Meter Taxi",
    desc: " Pool Meter Taxi, a flagship in the transport sector under our corporate umbrella, introduces an innovative ride-sharing solution. Leveraging advanced technology, the service promises efficient and affordable transportation, enhancing commuter experiences. This marks a significant stride towards sustainable urban mobility and underscores our commitment to redefining the future of transport.  ",
  },
  {
    id: 2,
    img: "/import.jpeg",
    title: "Import-Export: Origin Land ",
    desc: " Origin Land, our distinguished import-export company, takes a leap forward in facilitating international trade. Leveraging strategic partnerships and streamlined logistics, Origin Land ensures seamless import-export operations, fostering global commerce. This milestone reinforces our commitment to creating value across borders, promoting economic growth, and solidifying our position as a key player in the global trade landscape.  ",
  },

  {
    id: 3,
    img: "/union.jpg",
    title: "Union Acadamy",
    desc: " In the realm of education, our dedicated entity strives for excellence. Through innovative programs and a student-centric approach, Union acadamy empowers minds, shaping the leaders of tomorrow. With a commitment to quality education, the company stands as a beacon for knowledge, fostering a brighter future through learning and enlightenment.  ",
  },
];

// Home Gallary

export const gallary = [
  {
    id: 1,
    img: "/import.jpeg",
    title: "Sample Message",
    desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.quaerat distinctio aperiam",
  },
  {
    id: 2,
    img: "/import.jpeg",
    title: "Sample Message",
    desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.quaerat distinctio aperiam",
  },

  {
    id: 3,
    img: "/import.jpeg",
    title: "Sample Message",
    desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.quaerat distinctio aperiam",
  },
  {
    id: 4,
    img: "/import.jpeg",
    title: "Sample Message",
    desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.quaerat distinctio aperiam",
  },
];

// Gallary Page

export const gallary_page = [
  {
    id: 1,
    img: "/import.jpeg",
    title: "Sample Message",
    desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.quaerat distinctio aperiam",
  },
  {
    id: 2,
    img: "/import.jpeg",
    title: "Sample Message",
    desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.quaerat distinctio aperiam",
  },

  {
    id: 3,
    img: "/import.jpeg",
    title: "Sample Message",
    desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.quaerat distinctio aperiam",
  },
  {
    id: 4,
    img: "/import.jpeg",
    title: "Sample Message",
    desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.quaerat distinctio aperiam",
  },
  {
    id: 5,
    img: "/import.jpeg",
    title: "Sample Message",
    desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.quaerat distinctio aperiam",
  },
  {
    id: 6,
    img: "/import.jpeg",
    title: "Sample Message",
    desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.quaerat distinctio aperiam",
  },

  {
    id: 7,
    img: "/import.jpeg",
    title: "Sample Message",
    desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.quaerat distinctio aperiam",
  },
  {
    id: 8,
    img: "/import.jpeg",
    title: "Sample Message",
    desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.quaerat distinctio aperiam",
  },
];
