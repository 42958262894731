import React from "react";
import { FaArrowRight } from "react-icons/fa";

const Services = () => {
  return (
    <div className="bg-gray-50 overflow-hidden">
      <div className="hidden xl:flex absolute left-5 z-0 w-52 h-52 bg-orange-100 rounded-b-full"></div>
      <div className="w-full text-black">
        <div className="mx-6 md:mx:18 lg:mx-32 py-8">
          <h1 className="font font-bold text-xl lg:text-3xl font-sans mb-3 flex justify-center mx-auto">
            Our Services
          </h1>
          <p className="text-slate-700 max-w-2xl pb-5 flex justify-center mx-auto text-center text-sm lg:text-md">
            Our company is a multifaceted conglomerate, seamlessly operating
            seven specialized entities spanning transport, education,
            import-export, real estate, and more.
          </p>
        </div>
      </div>
      <div className="md:flex justify-center gap-14 items-center pb-16 mx-3">
        <img
          src={"/import.jpeg"}
          width={300}
          height={200}
          alt="img"
          className="mb-2 md:mb-0 md:flex rounded-md mx-auto md:mx-0"
        />
        <div>
          <h2 className="text-blue-950 text-xl md:text-xl font-bold text-center md:text-start">
            Import and Export
          </h2>
          <p className="max-w-xl py-3 pb-8 text-gray-700 text-center md:text-start">
          Our import-export division, Origin Land, is a key player in the global trade of diverse goods. We specialize in importing high-quality cars and foods, ensuring a wide range of choices for our customers. Simultaneously, we export premium coffee, pulses, and meat, contributing to international markets with top-notch products
          </p>
          <button className="mx-auto md:mx-0 flex justify-center items-center border bg-[#cd9c61] hover:bg-orange-800 duration-300 ease-in-out rounded-lg px-8 py-3 text-slate-50 font-bold">
            <a href="/">Read more</a>
            <span className="ml-2 flex justify-center items-center">
              <FaArrowRight size={12} className="text-white" />
            </span>
          </button>
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row justify-center gap-14 items-center pb-16 mx-3">
        <div>
          <h2 className=" text-blue-950 text-xl md:text-xl font-bold md:py-3 text-center md:text-start">
            Transportation Sector
          </h2>
          <p className="max-w-xl py-3 pb-8 text-gray-700 text-center md:text-start">
          Pool Meter Taxi, our premier transport service, offers a revolutionary approach to ride-sharing. With state-of-the-art meter technology, we provide efficient and cost-effective transportation solutions. Our fleet of well-maintained vehicles ensures a comfortable and secure journey, setting new standards in urban mobility. Experience convenience and reliability with Pool Meter Taxi
          </p>
          <button className="mx-auto md:mx-0 flex justify-center items-center border bg-[#cd9c61] hover:bg-orange-800 duration-300 ease-in-out rounded-lg px-8 py-3 text-slate-50 font-bold">
            <a href="/">Read more</a>
            <span className="ml-2 flex justify-center items-center">
              <FaArrowRight size={12} className="text-white" />
            </span>
          </button>
        </div>
        <img
          src={"/taxi1.png"}
          width={300}
          height={200}
          alt="img"
          className="md:flex rounded-md mx-auto md:mx-0"
        />
      </div>
      <div className="md:flex justify-center gap-14 items-center pb-16 mx-3">
        <img
          src={"/realstateq.png"}
          width={300}
          height={200}
          alt="img"
          className="mb-2 md:mb-0 md:flex rounded-md mx-auto md:mx-0"
        />
        <div>
          <h2 className="text-blue-950 text-xl md:text-xl font-bold text-center md:text-start">
            Realstate Construction
          </h2>
          <p className="max-w-xl py-3 pb-8 text-gray-700 text-center md:text-start">
          Our real estate sector is a testament to quality and commitment, offering a spectrum of properties that redefine living and working spaces. From residential havens to commercial hubs, we prioritize architectural excellence, sustainability, and community well-being. With a keen focus on innovation, our real estate ventures reflect a vision of modern living and dynamic environments.
          </p>
          <button className="mx-auto md:mx-0 flex justify-center items-center border bg-[#cd9c61] hover:bg-orange-800 duration-300 ease-in-out rounded-lg px-8 py-3 text-slate-50 font-bold">
            <a href="/">Read more</a>
            <span className="ml-2 flex justify-center items-center">
              <FaArrowRight size={12} className="text-white" />
            </span>
          </button>
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row justify-center gap-14 items-center pb-16 mx-3">
        <div>
          <h2 className=" text-blue-950 text-xl md:text-xl font-bold md:py-3 text-center md:text-start">
            Educational Sector
          </h2>
          <p className="max-w-xl py-3 pb-8 text-gray-700 text-center md:text-start">
          Union Academy, our esteemed education entity, stands as a beacon of learning excellence. Committed to nurturing minds, we offer innovative programs, experienced faculty, and state-of-the-art facilities. From personalized teaching to cutting-edge resources, Union Academy creates an enriching educational environment, empowering students for a successful and fulfilling future.
          </p>
          <button className="flex mx-auto md:mx-0 justify-center items-center border bg-[#cd9c61] hover:bg-orange-800 duration-300 ease-in-out rounded-lg px-8 py-3 text-slate-50 font-bold">
            <a href="/">Read more</a>
            <span className="ml-2 flex justify-center items-center">
              <FaArrowRight size={12} className="text-white" />
            </span>
          </button>
        </div>
        <img
          src={"/Educational1.png"}
          width={300}
          height={200}
          alt="img"
          className="md:flex rounded-md mx-auto md:mx-0"
        />
      </div>
    </div>
  );
};

export default Services;
