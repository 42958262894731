import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { nav_links } from "../constants/index";
import { HiBars3} from "react-icons/hi2";
import { FaFacebook,FaInstagram, FaXTwitter, FaTelegram } from "react-icons/fa6";
import { LiaTimesSolid } from "react-icons/lia";

const Navbar = () => {
  const pathname = useLocation();
  const [nav, setNav] = useState(false);
  const navHandle = () => {
    setNav(!nav);
  };

  return (
    <>
      <header className="top-0 left-0 w-full fixed z-[9999] py-5 md:px-8 px-4 bg-white border-b">
        <div className="container mx-auto">
          <div className="flex justify-between items-center">
            <div className="xl:pl-10">
              <a href={"/"}>
                <img
                  src={"/origin_logo.png"}
                  width={180}
                  height={200}
                  alt="img"
                />
              </a>
            </div>

            {/* Navigation Menu  */}

            <div className="hidden lg:block">
              <ul className="flex space-x-14 font-sans">
                {nav_links.map((item, index) => (
                  <li key={item.key}>
                    <a
                      href={item.href}
                      className={
                        pathname === item.href
                          ? "text-[#e2aa6d]  font-bold uppercase"
                          : "text-[#e2aa6d] hover:text-orange-400 hover:font-semibold ease-in-out duration-300 font-sans uppercase"
                      }
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="font-sans text-sm">
              <div className="hidden lg:flex gap-4">
                <a href={"/"} className="flex gap-3 justify-start items-center">
                  <FaFacebook size={21} className="text-[#cd9c61] hover:text-orange-400" />
                </a>
                <a href={"/"} className="flex gap-3 justify-center items-centerd">
                  <FaInstagram size={21} className="text-[#cd9c61] hover:text-orange-400" />
                </a>
                <a href={"/"} className="flex gap-3 justify-start items-center">
                  <FaXTwitter size={21} className="text-[#cd9c61] hover:text-orange-400" />
                </a>
                <a href={"/"} className="flex gap-3 justify-start items-center">
                  <FaTelegram size={21} className="text-[#cd9c61] hover:text-orange-400" />
                </a>
              </div>
              <button className="block lg:hidden" onClick={navHandle}>
                {!nav ? (
                  <HiBars3 size={35} className="text-black" />
                ) : (
                  <LiaTimesSolid size={35} className="text-black" />
                )}
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Navigation */}

      <div
        className={
          nav
            ? "z-[9999] fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-gray-950 ease-in-out duration-500"
            : "fixed left-[-100%]"
        }
      >
        <div className="flex items-center justify-center py-3">
          <a href={"/"}>
            <img
              src={"/origin_logo.png"}
              width={200}
              height={200}
              alt="img"
            />
          </a>
        </div>

        <ul className="p-4">
          {nav_links.map((a) => (
            <li key={a.key} className="p-4 border-b border-gray-900">
              <a href={a.href} className="uppercase text-white">
                {a.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Navbar;
