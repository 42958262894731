import React from "react";
import Companies from "../pages/Companies";

const Footer = () => {
  return (
    <div>
      <div className="bg-[#000300] w-full mx-auto py-5 px-6 lg:px-16 grid lg:grid-cols-3 gap-4 text-gray-400">
        <div>
          <h1 className="w-full text-3xl font-bold text-slate-50">
            Wogen Holdings
          </h1>
          <p className="py-4 text-sm font-sans">
            Step into a realm of corporate versatility where seven unique
            entities converge, each specializing in diverse sectors such as
            transport, education, import-export, and real estate. Together, we
            redefine excellence, weaving a tapestry of success and innovation.
          </p>
          <div className="flex justify-between mx-auto md:w-[100%]">
            <Companies />
          </div>
        </div>
        <div className="lg:col-span-2 flex justify-between lg:px-44 mt-6">
          <div>
            <h6 className="font-semibold text-gray-200">Company</h6>
            <ul className="flex flex-col">
              <a href="/about" className="py-2 text-sm hover:text-gray-200">
                About Wogen
              </a>
              <a href="/news" className="py-2 text-sm hover:text-gray-200">
                News
              </a>
            </ul>
          </div>
          <div>
            <h6 className="font-semibold text-gray-200">Contact</h6>
            <ul>
              <li className="py-2 text-sm">Call Us: +251-912-502288</li>
              <li className="py-2 text-sm">Email Us: info@wogenholdings.com</li>
              <li className="py-2 text-sm">
                Address: Bambis, Addis Ababa, Ethiopia
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-[#000300] w-full flex justify-center items-center mx-auto pb-2">
        <p className="text-gray-50 mx-auto">
          Wogenholdings 2024 | All rights reserved &copy;
        </p>
      </div>
    </div>
  );
};

export default Footer;
